<template>
  <div
    class="new-dashboard-tweet-card"
    :class="{
      large: size === 'lg',
      wide: size === 'xl',
      medium: size === 'md',
    }"
  >
    <dashboard-base-card class="relative h-full bg-ds-background" :hasBorder="hasBorder">
      <template v-if="showBody">
        <div
          class="card-body"
          :class="{ 'max-h-300 overflow-y-hidden rounded-b-lg': isExpandable && showExpand }"
          ref="body"
        >
          <slot></slot>
        </div>
      </template>

      <div v-if="$slots.footer" class="absolute inset-x-0 bottom-0 px-8 pb-4">
        <slot name="footer"></slot>
      </div>

      <template v-if="showBody">
        <div
          class="flex w-full items-end justify-between rounded-b-lg bg-ds-background px-8 pb-4"
          :class="isExpandable && showExpand && 'fade-effect absolute bottom-0 h-24'"
        >
          <div class="pt-2.5">
            <slot name="extra"></slot>
          </div>
          <div v-if="isExpandable">
            <new-button v-if="showExpand" @click="showExpand = false" type="secondary">
              <span>Show more</span>
            </new-button>

            <new-button v-else @click="showExpand = true" type="secondary">
              <span>Show less</span>
            </new-button>
          </div>
        </div>
      </template>
    </dashboard-base-card>
    <div v-if="$slots.action" class="flex items-center gap-1">
      <slot name="action"></slot>
    </div>
  </div>
</template>
<script>
  import DashboardBaseCard from '../DashboardBaseCard.vue';
  export default {
    name: 'TweetVariationCard',

    components: {
      DashboardBaseCard,
    },
    props: {
      size: {
        type: String,
        default: 'md',
        validator: (value) => ['md', 'lg', 'xl'].includes(value),
      },
      canCollapse: {
        type: Boolean,
        default: false,
      },
      hasBody: {
        type: Boolean,
        default: true,
      },
      isCollapsable: {
        type: Boolean,
        default: false,
      },
      hasBorder: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        showExpand: true,
        isCollapsed: true,
        isExpandable: false,
      };
    },
    computed: {
      showBody() {
        if (!this.hasBody) {
          return false;
        }
        if (this.canCollapse) {
          return !this.isCollapsed;
        }
        return true;
      },
    },
    mounted() {
      if (this.isCollapsable) {
        const cardBody = this.$refs.body;
        this.$nextTick(() => {
          if (cardBody) {
            const cardBodyHeight = cardBody.clientHeight;
            const maxHeight = 230;
            if (cardBodyHeight > maxHeight && cardBodyHeight - maxHeight > 30) {
              this.isExpandable = true;
            }
          }
        });
      }
    },
  };
</script>
<style lang="scss" scoped>
  .new-dashboard-tweet-card {
    &.large {
      max-width: 100%;
      width: 100%;
    }
    &.wide {
      width: 400px;
    }
    &.medium {
      max-width: 290px;
    }
  }
</style>
