<template>
  <div
    class="border rounded-lg"
    :class="{
      'border-ds-outline-primary': hasBorder,
      'border-none': !hasBorder,
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'DashboardBaseCard',
    props: {
      hasBorder: {
        type: Boolean,
        default: true,
      },
    },
  };
</script>
